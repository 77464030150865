import "./../styles/articleTemplate.scss"
import React, { useEffect, useRef, useState } from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import TwitterSvg from "../components/svg/twitter"
import LinkedinSvg from "../components/svg/linkedin"
import { ENV_SITE_URL } from "../utils/secrets"
import { AUTHORS } from "../utils/constants"
import { graphql, Link } from "gatsby"
import { Helper } from "../utils/helper"
import withAppContext from "../contexts/app.context"
import MailSvg from "../components/svg/mail"
import Helmet from "react-helmet"

const ArticleTemplate = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html, fields } = markdownRemark

  const containerRef = useRef()
  const shareRef = useRef()
  const shareOffset = useRef(0)

  const [shareFloating, setShareFloating] = useState(true)

  const isBlogArticle = fields.category === "blogs"

  const posterTitle = `${frontmatter.title}`
  const relativeUrl = `/${isBlogArticle ? "resources/blog" : "company/news"}/${
    frontmatter.slug
  }`
  const articleUrl = ENV_SITE_URL + relativeUrl

  const twitterShareUrl = Helper.getTwitterShareUrl(posterTitle, articleUrl)
  const linkedInShareUrl = Helper.getLinkedinShareUrl(posterTitle, articleUrl)

  const isAuthorLinked = author => {
    return AUTHORS.includes(author)
  }

  const getAuthorLink = author => {
    if (author && isAuthorLinked(author)) {
      let authorUrl = Helper.getAuthorUrl(author)
      return <Link to={authorUrl}>{author}</Link>
    } else {
      return <span>{author}</span>
    }
  }

  useEffect(() => {
    const _shareTop = shareRef.current.getBoundingClientRect().top
    const _shareHeight = shareRef.current.getBoundingClientRect().height
    // TODO Change constant while changing margin-bottom of .article.p
    shareOffset.current = _shareTop + _shareHeight + 20.8 // 1.3px Margin Bottom

    document.addEventListener("scroll", onScroll)
    return () => document.removeEventListener("scroll", onScroll)
  }, [])

  const onScroll = event => {
    const _containerTop = containerRef.current.getBoundingClientRect().top
    const _containerHeight = containerRef.current.getBoundingClientRect().height

    setShareFloating(_containerTop + _containerHeight > shareOffset.current)
  }

  return (
    <Layout>
      <Helmet>
        {isBlogArticle && (
          <script type="application/ld+json">
            {`
              @context: "https://schema.org",
              @type: "BlogPosting",
              mainEntityOfPage: {
                @type: "WebPage",
                @id: "${articleUrl}"
              },
              headline: "${frontmatter.title}",
              image: "${
                !!frontmatter.metaImage
                  ? frontmatter.metaImage.publicURL
                  : undefined
              }",
              author: {
                @type: "Person",
                name: "${frontmatter.author}"
              },
              publisher: {
                @type: "Organization",
                name: "Volterra",
                logo: {
                  @type: "ImageObject",
                  url: "${ENV_SITE_URL}/img/common/v-logo.png",
                  width: 60,
                  height: 60
                }
              },
              datePublished: "${frontmatter.blogDate}",
              dateModified: "${frontmatter.blogDate}"
            `}
          </script>
        )}
      </Helmet>
      <div className="container">
        <SEO
          title={frontmatter.metaTitle || `${frontmatter.title} | Volterra`}
          pageUrl={relativeUrl}
          description={frontmatter.excerpt}
          keywords={frontmatter.metaKeywords}
          imageName={
            !!frontmatter.metaImage
              ? frontmatter.metaImage.publicURL
              : undefined
          }
          twImageName={
            !!frontmatter.metaImageTw
              ? frontmatter.metaImageTw.publicURL
              : undefined
          }
          isArticle={true}
          author={frontmatter.author || frontmatter.channel}
          publishedAt={frontmatter.date}
        />

        <div className="article__outer">
          <div className="row article__container" ref={containerRef}>
            <div className={`share d-flex ${shareFloating ? "float" : "end"}`}>
              <div className="share__content" ref={shareRef}>
                <span>Share</span>
                <a
                  aria-label="Share on Twitter"
                  className="share__icon"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={twitterShareUrl}>
                  <TwitterSvg />
                </a>
                <a
                  aria-label="Share on Linkedin"
                  className="share__icon"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={linkedInShareUrl}>
                  <LinkedinSvg />
                </a>
                <a
                  aria-label="Share via email"
                  className="share__icon"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={Helper.getEmailShareUrl(
                    isBlogArticle ? "blog post" : "news",
                    posterTitle,
                    articleUrl,
                    frontmatter.excerpt
                  )}>
                  <MailSvg />
                </a>
              </div>
            </div>
            <div className="article">
              <div className="article__header">
                <span className="mr-4">{frontmatter.blogDate}</span>|
                <span className="ml-4">
                  {getAuthorLink(frontmatter.author)}
                  {frontmatter.author2 && (
                    <span>
                      {!frontmatter.author3 && <span> and </span>}
                      {frontmatter.author3 && <span>, </span>}
                    </span>
                  )}
                  {getAuthorLink(frontmatter.author2)}
                  {frontmatter.author3 && <span> and </span>}
                  {getAuthorLink(frontmatter.author3)}
                  {frontmatter.channel}
                </span>
              </div>
              <h1 className="article__title">{frontmatter.title}</h1>
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      fields {
        category
      }
      frontmatter {
        title
        author
        author2
        author3
        channel
        excerpt
        slug
        date
        blogDate: date(formatString: "MMMM DD, YYYY")
        newsDate: date(formatString: "MMM YYYY")
        metaTitle
        metaKeywords
        metaImage {
          publicURL
        }
        metaImageTw {
          publicURL
        }
      }
    }
  }
`

export default withAppContext(ArticleTemplate)
